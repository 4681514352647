import { ContactContainer, ContactParagraph } from "styles/ContactStyles";

const Contact = () => {
  return (
    <ContactContainer>
      <ContactParagraph>songminji1918@gmail.com</ContactParagraph>
    </ContactContainer>
  );
};

export default Contact;
